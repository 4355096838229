import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = () => (
    <Layout>
      <SEO title="Strony internetowe" />


    </Layout>
)

export default Page
